















































































































































import Vue from "vue";

import SwitchButton from "@/components/SwitchButton.vue";

export default Vue.extend({
	components: {
		SwitchButton
	},
	data() {
		return {
			members: [],
			sortKey: "",
			sortBy: false,
			currentUser: 0 as any,
			currentRank: 0 as any
		};
	},
	created() {
		this.reload();
	},
	methods: {
		reload() {
			this.$store.commit("pushLoading", {
				name: "GET_CLUB_DETAIL_MEMBERS",
				message: "동아리 맴버 불러오는 중"
			});
			this.$store
				.dispatch("GET_CLUB_DETAIL_MEMBERS")
				.then(members => {
					this.$store.commit("clearLoading", "GET_CLUB_DETAIL_MEMBERS");

					this.members = members;
				})
				.catch(err => {});
		},
		orderBy(key: string) {
			if (this.sortKey == key) {
				this.sortBy = !this.sortBy;
			} else {
				this.sortKey = key;
			}
		},
		appendRank() {
			this.getClub.ranks.push({
				id: this.getClub.ranks[this.getClub.ranks.length - 1].id + 1,
				name: "이름",
				permission: []
			});
			this.currentRank = this.getClub.ranks.length - 1;
		},
		removeRank(idx: number) {
			this.currentRank = 0;
			this.getClub.ranks.splice(idx, 1);
		},
		getRankString(id: number) {
			return this.getClub.ranks.find((x: any) => x.id == id).name;
		},
		commit() {
			let isValid = true;
			this.getClub.ranks.map((x: any) => {
				if (x.name.trim().length <= 0) isValid = false;
			});
			if (isValid) {
				this.$store.commit("pushLoading", {
					name: "CLUB_MODIFICATION",
					message: "동아리 맴버 수정 중"
				});
				this.$store
					.dispatch("CLUB_MODIFICATION", {
						ranks: this.getClub.ranks,
						members: this.members.map((x: any) => {
							x.user = x.user._id;
							return x;
						})
					})
					.then(club => {
						this.$store.commit("clearLoading", "CLUB_MODIFICATION");
						this.reload();
					})
					.catch(err => console.log(err));
			}
		},
		fire() {
			this.$store.commit("pushLoading", {
				name: "CLUB_FIRE_MEMBER",
				message: "동아리 맴버 퇴출 중"
			});
			this.$store
				.dispatch("CLUB_FIRE_MEMBER", {
					_id: this.getOrderedMembers[this.currentUser].user
				})
				.then(club => {
					this.currentUser = 0;
					this.$store.commit("clearLoading", "CLUB_FIRE_MEMBER");
					this.reload();
				})
				.catch(err => console.log(err));
		}
	},
	computed: {
		getClub() {
			return this.$store.state.club;
		},
		getOrderedMembers(): any {
			if (this.sortKey == "") {
				return this.members;
			} else {
				return this.members.sort((a: any, b: any): any => {
					if (this.sortBy) return b[this.sortKey] > a[this.sortKey] ? 1 : -1;
					else return a[this.sortKey] > b[this.sortKey] ? 1 : -1;
				});
			}
		}
	}
});
